import React, { useEffect, useState } from "react";
import pdfPlaceHolder from "./VisitationImageSection/pdf.jpg";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CompletedVisitView = (props) => {
  const [forceF, SetForce] = useState({});

  useEffect(() => {
    SetForce({});
  }, [props.state]);
  const getState = () => {
    console.log("in it", props.state.dischargReport);
    if (
      props.state.dischargReport == null ||
      props.state.dischargReport === undefined ||
      props.state.dischargReport.note == null ||
      props.state.dischargReport.note == "" ||
      props.state.dischargReport.note == undefined
    ) {
      return EditorState.createEmpty();
    } else {
      return EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(JSON.parse(props.state?.dischargReport?.note))
        )
      );
    }
  };
  const getType = (type) => {
    if (type == "examination") {
      return "Examination";
    } else if (type == "investigation") {
      return "Investigation";
    } else if (type == "diagnosis") {
      return "Differential diagnosis & diagnosis";
    } else if (type == "chiefComplain") {
      return "Chief Complain";
    } else if (type == "followUp") {
      return "Plan & follow up";
    }
    return type;
  };
  const state = props.state;

  const chiefComplain = state.currentLlenuss.find(
    (x) => x.type === "chiefComplain"
  );
  const followUp = state.currentLlenuss.find((x) => x.type === "followUp");
  const diagnosis = state.currentLlenuss.find((x) => x.type === "diagnosis");
  const examination = state.currentLlenuss.find(
    (x) => x.type === "examination"
  );
  const investigation = state.currentLlenuss.find(
    (x) => x.type === "investigation"
  );
  console.log("stfollowUpate", followUp);
  let currentLlenuss = [
    <div>
      <p>
        <span className="text-bold">{getType(chiefComplain.type)}</span>
        <br /> {chiefComplain.note}
      </p>
      <hr />{" "}
      <p>
        <span className="text-bold">{getType(examination.type)}</span>
        <br /> {examination.note}
      </p>
      <hr />{" "}
      <p>
        <span className="text-bold">{getType(investigation.type)}</span>
        <br /> {investigation.note}
      </p>
      <hr />{" "}
      <p>
        <span className="text-bold">{getType(diagnosis.type)}</span>
        <br /> {diagnosis.note}
      </p>
      <hr />{" "}
      <p>
        <span className="text-bold">{getType(followUp.type)}</span>
        <br /> {followUp.note}
      </p>
      <hr />
    </div>,
  ];
  let medicalHis = [];
  state.patient.medical_history.forEach((element) => {
    medicalHis.push(
      <p>
        {element.disease} : {element.note}
      </p>
    );
  });
  let treatment = [];
  state.treatment.forEach((element) => {
    treatment.push(
      <div>
        <p>
          <span className="text-bold">{element.drug.name}</span> <br />{" "}
          {element.note}
        </p>
        <hr />
      </div>
    );
  });
  let images = [];
  state.images?.forEach((e) => {
    images.push(
      <a
        href={e.url}
        target="_blank"
        style={{
          margin: "10px",
        }}
      >
        {e.url.toString().toLowerCase().endsWith("pdf") == false ? (
          <img width="100" height="100" src={e.url} />
        ) : (
          <img width="100" height="100" src={pdfPlaceHolder} />
        )}
      </a>
    );
  });
  return (
    <div>
      <div className="badge badge-info p-2" role="alert">
        <h4 className="d-inline-block">price : {state?.price}</h4>
      </div>
      <div className="badge badge-primary p-2 d-inline-block mx-2" role="alert">
        <h4 className="d-inline-block">{state?.created_at}</h4>
      </div>

      {currentLlenuss.length > 0 ? (
        <div className="card mt-2">
          <div className="card-header bg-info">
            <h5>HISTORY OF CURRENT ILLNESS</h5>
          </div>
          <div className="card-body">{currentLlenuss}</div>
        </div>
      ) : (
        <div></div>
      )}

      {treatment.length > 0 ? (
        <div className="card mt-2">
          <div className="card-header bg-info">
            <h5>Treatments</h5>
          </div>
          <div className="card-body">{treatment}</div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="card mt-2">
        <div className="card-header bg-info">
          <h5>Report</h5>
        </div>
        <div className="card-body">
          <Editor
            editorState={getState()}
            readOnly={true}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            defaultEditorState={{
              blocks: [],
              entityMap: {},
            }}
            toolbar={{
              options: [],
              inline: { inDropdown: false },
            }}
          />
        </div>
      </div>

      {images.length > 0 ? (
        <div className="card mt-2">
          <div className="card-header bg-info">
            <h5>Visition Image</h5>
          </div>
          <div className="card-body">{images}</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CompletedVisitView;
