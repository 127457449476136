import swal from "sweetalert";
import api from "../constants/api";
import {
  DELETE_PATION_FAIL,
  DELETE_PATION_REQUEST,
  DELETE_PATION_SUCCESS,
  GET_PATION_FAIL,
  GET_PATION_REQUEST,
  GET_PATION_SUCCESS,
} from "../constants/newinvocesConstants";
import { getToken } from "../reducers/userReducers";
import store from "../store";
import { ADD_CVS } from "../component/todayPatient/EachVisition/VisitationImageSection/VisitationImage";

export const addPationAction = async (
  fullname,
  phone,
  birthdate,
  gender,
  blood_type,
  address,
  occupation_id,
  doctor_id,
  marital_status,
  alkohol,
  smoking,
  appointment,
  files,
  resetState
) => {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    var formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("phone", phone);
    formData.append("birthdate", birthdate);
    formData.append("gender", gender);
    formData.append("blood_type", blood_type);
    formData.append("address", address);
    formData.append("occupation_id", occupation_id);
    formData.append("doctor_id", doctor_id);
    formData.append("marital_status", marital_status);
    formData.append("alkohol", alkohol);
    formData.append("smoking", smoking);

    const { data } = await api.post("patients/create", formData, config);

    resetState();
    uploadCVS(files, data.id, false);
    addVivisit(data.id, appointment, getToken());
  } catch (error) {
    swal.close();
    await swal("Opps!", "Failed to add patient", "error");
  }
};

async function uploadCVS(files, patient_id, isFromProfile) {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    var formData = new FormData();

    if (files?.length > 0) {
      files.forEach((file, index) => {
        formData.append("img" + index, file);
      });
    } else {
      return;
    }
    formData.append("patient_id", patient_id);

    const { data } = await api.post("patients/upload-cv", formData, config);

    if (isFromProfile == true) {
      store.dispatch({ type: ADD_CVS, payload: data });
    }
    swal("Good job!", "patient cv Was uploded successfully", "success");
  } catch (error) {
    console.log(error);
    swal.close();
    await swal(
      "Opps!",
      "Failed to Upload patient Cv please upload them again in patient profile",
      "error"
    );
  }
}

const addVivisit = async (id, appointment, token) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.post(
      "visits/create",
      {
        patient_id: id,
        state: "the visit is here",
        follow_date: appointment,
        price: 25000,
      },
      config
    );

    await swal("Good job!", "visits was aded successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add visits", "error");
  }
};

export const editPatinet = async (
  fullname,
  phone,
  birthdate,
  gender,
  blood_type,
  address,
  occupation_id,
  doctor_id,
  marital_status,
  alkohol,
  smoking,
  appointment,
  files,
  patiner_id,
  reset
) => {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };
    uploadCVS(files, patiner_id, true);

    var formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("phone", phone);
    formData.append("birthdate", birthdate);
    formData.append("gender", gender);
    formData.append("blood_type", blood_type);
    formData.append("address", address);
    formData.append("appointment", appointment);
    formData.append("occupation_id", occupation_id);
    formData.append("doctor_id", doctor_id);
    formData.append("marital_status", marital_status);
    formData.append("alkohol", alkohol);
    formData.append("smoking", smoking);
    formData.append("id", patiner_id);

    const { data } = await api.post(
      "patients/update/" + patiner_id,
      formData,
      config
    );
    reset();
    swal("Good job!", "patient information Was Saved successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to save changes", "error");
  }
};

export const deleteCVS = async (id) => {
  try {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.delete(
      "patients/delete-cv/" + id,

      config
    );

    store.dispatch({ type: DELETE_CVS, payload: id });
    swal("Good job!", "CVS Was Deleted successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to deleted CVS", "error");
  }
};
export const DELETE_CVS = "DELETE_CVS";
