import swal from "sweetalert";

const showLoading = () => {
  swal({
    text: "Please wait...",
    closeOnClickOutside: false,
    buttons: false,
  });
};
export default showLoading;
