import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  saveMedicalHistory,
  updateMedicalHistory,
} from "../EachVisition/create_visit_reducer";

const HestoryVisit = (props) => {
  const sizeYes = {
    width: "15%",
    minWidth: "100px",
  };
  const sizeDrug = {
    width: "60%",
  };
  const [state, setState] = useState();
  let ex = props.state;
  let patient_id = props.patient_id;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type + "Range"] = e.range;
        temp[e.disease] = e.note;
      });
      setState({ ...temp });
    } else {
      setState({
        pastMedicalHistory: "",
        pastMedicalHistoryRange: "0",
        drugAndSocialHistory: "",
        drugAndSocialHistoryRange: "0",
        pastSurgicalHistory: "",
        pastSurgicalHistoryRange: "0",

        other: "",

        otherRange: "0",
      });
    }
  }, []);
  console.log(state);
  const handeSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let ranges = [];
    let notes = [];
    let diseases = [];
    Object.entries(state).forEach(([key, value]) => {
      if (key.toString().toLowerCase().includes("range")) {
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });

    let ids = [];
    setDisble(true);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateMedicalHistory(types, ranges, notes, patient_id, ids);
      setDisble(false);
    } else {
      await saveMedicalHistory(types, ranges, notes, patient_id);
      setDisble(false);
    }
  };

  return (
    <div>
      <form onSubmit={handeSubmit}>
        <section>
          <div className="input-group input-group-lg">
            <div className="w-100">
              <label htmlFor="pastMedicalHistory">Past Medical History</label>
              <textarea
                placeholder="Past Medical History"
                value={state?.pastMedicalHistory}
                onChange={(e) => {
                  setState({ ...state, pastMedicalHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>

          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="pastSurgicalHistory">Past Surgical History</label>
              <textarea
                placeholder="Past Surgical History"
                value={state?.pastSurgicalHistory}
                onChange={(e) => {
                  setState({ ...state, pastSurgicalHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="drugAndSocialHistory">
                Drug And Social History
              </label>
              <textarea
                placeholder="Drug And Social History"
                value={state?.drugAndSocialHistory}
                onChange={(e) => {
                  setState({ ...state, drugAndSocialHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="other">other</label>
              <textarea
                placeholder="other"
                value={state?.other}
                onChange={(e) => {
                  setState({ ...state, other: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
        </section>

        <button
          disabled={disable}
          className="btn btn-success btn-lg mt-3 w-100"
          type="submit"
        >
          Submit
        </button>
      </form>{" "}
    </div>
  );
};

export default HestoryVisit;
